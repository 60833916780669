<template>
  <div>
    <file-pond
        v-if="model.added && model.id"
        class="mt-3"
        :server="server"
        :label-idle="gettext('Vložte alebo <span class=\'filepond--label-action\'>vyberte súbor</div>')"
        @addfile="handleFileUpload"
    />
    <div v-else>
      <button @click.prevent="downloadFile"
              class="btn btn-reset-table btn-outline-primary m-2 btn-sm">
        <template v-if="!loading">
          {{ gettext('Stiahnuť') }}
          <i class="fa fa-download"></i>
        </template>
        <i v-else class="spinner-border-sm spinner-border text-primary"></i>
      </button>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  // Import Vue FilePond
  import vueFilePond from 'vue-filepond';

  // Import FilePond styles
  import 'filepond/dist/filepond.min.css';
  import {abstractField} from "vue-form-generator";

  const FilePond = vueFilePond();

  export default {
    components: {
      FilePond
    },
    data() {
      return {
        loading: false,
        server: {
          url: null,
          revert: null,
          headers: {'X-CSRFToken': this.$cookie.get('csrftoken')},
        },
      }
    },
    mixins: [abstractField],
    watch: {
      model: {
        handler(newVal) {
          if (newVal.id) {
            this.server.url = Urls['upload-file-api'](newVal.id);
          }
        },
        immediate: true
      }
    },
    methods: {
      downloadFile() {
        this.loading = true;
        const url = this.getFilePathFromId(this.model.id)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = this.model.nazev
          link.click()
          URL.revokeObjectURL(link.href)
        }).finally(() => {
          this.loading = false;
        })
      },
      getFilePathFromId(fileId) {
        return Urls['upload-file-api'](fileId.toLowerCase());
      },
      handleFileUpload(error, file) {
        Vue.set(this.model, 'nazev', file.filename);
      }
    }
  };
</script>
<style lang="scss">
</style>