<template>
  <div class="p-2 shadow-none border">
    <slot v-if="title" name="title" :title="title">
      <h5 class="address__title">{{ title }}</h5>
    </slot>
    <template>
      <ul class="address">
        <li><b>{{ selectedAddress.full_name }}</b></li>
        <li>{{ selectedAddress.street }}, {{ selectedAddress.postal_code }} {{ selectedAddress.town }}</li>
        <li>{{ selectedAddress.country }}</li>
      </ul>
    </template>
    <slot name="additional_data" :selectedAddress="selectedAddress">
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'Address',
    props: {
      title: {
        type: String
      },
      selectedAddress: {
        required: true
      },
    }
  };
</script>

<style src="./Address.scss" lang="scss"/>