window.$ = window.jQuery = require('jquery');
require('jquery-ui')
require('jquery-ui/ui/widgets/sortable')
require('jquery-ui/ui/disable-selection')
require('dragtable/jquery.dragtable.js')
require('./notifications');
window.daterangepicker = require('daterangepicker');
window.moment = require('moment');

import 'vue-loading-overlay/dist/vue-loading.css';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import 'bootstrap-table/dist/bootstrap-table.min.css';
import 'daterangepicker/daterangepicker.css';

import Vue from 'vue';
import VueCookie from 'vue-cookie';
import {VueHammer} from 'vue2-hammer'
import 'bootstrap';
import BootstrapVue from 'bootstrap-vue';
import Loading from 'vue-loading-overlay';
import VueFormGenerator from 'vue-form-generator';

import store from './store';
import router from './Routes';
import App from './App';
import axios from "axios";
import {Multiselect} from "vue-multiselect";
import {formatPercentage, formatPrice, getPrimaryPrice} from "./utils";
import fieldRawHtml from "./components/VueFormGeneratorCustom/fieldRawHtml";
import fieldDateRange from "./components/VueFormGeneratorCustom/fieldDateRange";
import fieldDetailTable from "./components/VueFormGeneratorCustom/fieldDetailTable";
import './filters'
import fieldCheckboxB4 from "./components/VueFormGeneratorCustom/fieldCheckboxB4";
import fieldVueMultiSelectV2 from "./components/VueFormGeneratorCustom/fieldVueMultiSelectV2";
import vClickOutside from 'v-click-outside';
import VueMq from 'vue-mq';
import {VueFormGeneratorResources} from "./mixins/vue-form-generator-resources";
import fieldFileUpload from "./components/VueFormGeneratorCustom/fieldFileUpload";
import './registerServiceWorker';
import fieldListRadios from "@/components/VueFormGeneratorCustom/fieldListRadios";
import fieldMultipleListRadios from "@/components/VueFormGeneratorCustom/fieldMultipleListRadios";
import fieldCustomerAddressSelect from "@/components/VueFormGeneratorCustom/fieldCustomerAddressSelect";

Vue.use(BootstrapVue);
Vue.use(Loading);
Vue.use(VueCookie);
Vue.use(VueHammer);
Vue.use(VueMq, {
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: Infinity,
  }
});

Vue.component("fieldRawHtml", fieldRawHtml);
Vue.component("fieldDateRange", fieldDateRange);
Vue.component("fieldDetailTable", fieldDetailTable);
Vue.component("fieldCheckboxB4", fieldCheckboxB4);
Vue.component("fieldVueMultiSelectV2", fieldVueMultiSelectV2);
Vue.component("fieldFileUpload", fieldFileUpload);
Vue.component("fieldListRadios", fieldListRadios);
Vue.component("fieldMultipleListRadios", fieldMultipleListRadios);
Vue.component("fieldCustomerAddressSelect", fieldCustomerAddressSelect);
Vue.use(VueFormGenerator, {
  validators: {
    customerSelectValidator: (value, field, model) => {
      let errors = [];
      if (!value?.[field.model]) {
        errors.push(VueFormGeneratorResources.fieldIsRequired);
      }
      return errors;
    },
  }
});
Vue.use(vClickOutside);
Object.assign(VueFormGenerator.validators.resources, VueFormGeneratorResources);

Vue.component('multiselect', Multiselect);

Vue.prototype.$http = axios;
Vue.prototype.Urls = window.Urls;
Vue.prototype.SB_BE_VARIABLES = window.SB_BE_VARIABLES;
Vue.prototype.gettext = window.gettext;
Vue.prototype.ngettext = window.ngettext;
Vue.prototype.interpolate = window.interpolate;
window.axios = axios;
store.$http = Vue.prototype.$http;
Vue.config.productionTip = false;

/* eslint-disable no-new */
window.VUE = new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
});

Vue.mixin({
  methods: {
    formatPrice: formatPrice,
    formatPercentage: formatPercentage,
    getPrimaryPrice: getPrimaryPrice
  }
});