import Vue from 'vue';
import store from './store';
import Router from 'vue-router';

const Layout = () => import('@/components/Layout/Layout');
const Login = () => import('@/pages/Login/Login');
const ErrorPage = () => import('@/pages/Error/Error');
const ResetPassword = () => import('@/pages/Login/ResetPassword');

import isScreen from "./core/screenHelper";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    //hack for error in vue-form-wizard
    if (err?.name === "NavigationDuplicated" && err.message.includes("app/checkout/basket")) {
      return;
    }
    console.error(err);
  })
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/app',
      redirect: '/app/dashboard'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      props: true
    },
    {
      path: '/login/:deprecated',
      name: 'LoginDeprecated',
      component: Login,
      props: true
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/reset-password/:uidb64/:token',
      name: 'ResetPasswordConfirm',
      component: ResetPassword,
    },
    {
      path: '/app',
      name: 'Layout',
      component: Layout,
      children: [
        //  this will be populated dynamically, look to @/config/default/routes.js
      ],
    },
    {path: "*", name: '404', component: ErrorPage}
  ],
});

function checkGuards(requiresAuth, requiresGroup, requiresPermissions, next, to) {
  let isAllowed = true
  if (requiresGroup || requiresPermissions) {
    if (requiresGroup && store.getters['auth/hasGroup'](requiresGroup)) {
      isAllowed = true
    } else {
      isAllowed = !!(requiresPermissions && store.getters['auth/hasPerm'](requiresPermissions))
    }
  }
  if (isAllowed) {
    next({
      path: to?.path,
      query: to?.query
  })
  } else {
    next({
      path: '/login',
      params: {nextUrl: to?.fullPath}
    })
  }
}

router.beforeEach((to, from, next) => {
  if (isScreen('md') || isScreen('sm') || isScreen('xs')) {
    store.commit('layout/switchSidebar', true);
  }
  if (to.path === '/login' && store.state.auth.user) {
    next({
      path: '/app/dashboard'
    })
    return
  }
  if (to.matched.some(record => record.meta.demoDenied) && store.state.auth.user.config_data.is_demo) {
    return
  }
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresGroup = to.meta?.requiresGroup;
  const requiresPermissions = to.meta?.requiresPermissions;
  if (requiresAuth || requiresGroup || requiresPermissions || to.path.startsWith('/app/')) {
    if (store.state.auth.user == null) {
      store.dispatch('auth/fetchUser').then(() => {
        checkGuards(requiresAuth, requiresGroup, requiresPermissions, next, to)
      }).catch(() => {
        next({
          path: '/login',
          params: {nextUrl: to.fullPath}
        })
      })
    } else {
      checkGuards(requiresAuth, requiresGroup, requiresPermissions, next, null)
    }
  } else {
    if (store.state.auth.tenant == null) {
      store.dispatch('auth/fetchUser').then(() => {
      }).catch((e) => {
      })
    }
    if (store.state.auth.tenant == null) {
      store.dispatch('auth/fetchTenant').then(() => {
        if (store.state.auth.user == null && VUE.$cookie.get('current_language') && VUE.$store.state.auth.current_language == null) {
          VUE.$store.commit("auth/changeLanguage", VUE.$cookie.get('current_language'));
        }
        next(to.path)
      }).catch((e) => {
        next()
      })
    } else {
      next()
    }
  }
})
export default router