<template>
  <div class="radio-list" :disabled="disabled" v-attributes="'wrapper'">
    <b-form-group :label="schema.label">
      <b-form-radio
          v-for="item in items"
          :key="item.id"
          @change="onSelection(item)"
          :checked="isItemChecked(item)"
          :id="getFieldID(schema, true)"
          :disabled="isItemDisabled(item)"
          :name="id"
          :class="schema.fieldClasses"
          :required="schema.required"
          :value="getItemValue(item)">{{ getItemName(item) }}
      </b-form-radio>
    </b-form-group>
  </div>
</template>

<script>
import {isObject, isFunction, get as objGet} from "lodash";
import {abstractField} from "vue-form-generator";

export default {
  mixins: [abstractField],
  computed: {
    items() {
      let values = this.schema.values;
      if (typeof values == "function") {
        return values.apply(this, [this.model, this.schema]);
      } else {
        return values;
      }
    },
    id() {
      return this.schema.model;
    }
  },
  methods: {
    getItemValue(item) {
      if (isObject(item)) {
        if (typeof this.schema["radiosOptions"] !== "undefined" && typeof this.schema["radiosOptions"]["value"] !== "undefined") {
          return item[this.schema.radiosOptions.value];
        } else {
          if (typeof item["value"] !== "undefined") {
            return item.value;
          } else {
            throw "`value` is not defined. If you want to use another key name, add a `value` property under `radiosOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values";
          }
        }
      } else {
        return item;
      }
    },
    getItemName(item) {
      if (isObject(item)) {
        if (typeof this.schema["radiosOptions"] !== "undefined" && typeof this.schema["radiosOptions"]["name"] !== "undefined") {
          return item[this.schema.radiosOptions.name];
        } else {
          if (typeof item["name"] !== "undefined") {
            return item.name;
          } else {
            throw "`name` is not defined. If you want to use another key name, add a `name` property under `radiosOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values";
          }
        }
      } else {
        return item;
      }
    },
    getItemCssClasses(item) {
      return {
        "is-checked": this.isItemChecked(item),
        "is-disabled": this.isItemDisabled(item)
      };
    },
    onSelection(item) {
      this.value = this.getItemValue(item);
    },
    isItemChecked(item) {
      return this.value;
    },
    isItemDisabled(item) {
      if (this.disabled) {
        return true;
      }
      let disabled = objGet(item, "disabled", false);
      if (isFunction(disabled)) {
        return disabled(this.model);
      }
      return disabled;
    }
  }
};
</script>

<style lang="scss">
.vue-form-generator .field-listRadios {
  > label {
    display: none;
  }
  legend {
    margin-bottom: 1rem;
  }
  .errors {
    position: static;
  }
}
</style>