<template>
    <multiselect ref="multiselect" :id="selectOptions.id" :options="options" :value="value" :multiple="selectOptions.multiple"
                 :track-by="selectOptions.trackBy || null" :label="selectOptions.label || null"
                 :searchable="selectOptions.searchable" :clear-on-select="selectOptions.clearOnSelect"
                 :hide-selected="selectOptions.hideSelected" :placeholder="schema.placeholder"
                 :allow-empty="selectOptions.allowEmpty" :reset-after="selectOptions.resetAfter"
                 :close-on-select="selectOptions.closeOnSelect" :custom-label="customLabel"
                 :taggable="selectOptions.taggable"
                 :tag-placeholder="selectOptions.tagPlaceholder" :max="schema.max || null"
                 :options-limit="selectOptions.optionsLimit" :group-values="selectOptions.groupValues"
                 :group-label="selectOptions.groupLabel" :block-keys="selectOptions.blockKeys"
                 :internal-search="selectOptions.internalSearch"
                 :showNoOptions="selectOptions.showNoOptions"
                 :select-label="selectOptions.selectLabel" :selected-label="selectOptions.selectedLabel"
                 :deselect-label="selectOptions.deselectLabel" :show-labels="selectOptions.showLabels"
                 :limit="selectOptions.limit" :limit-text="selectOptions.limitText" :loading="selectOptions.loading"
                 :disabled="disabled" :max-height="selectOptions.maxHeight" :show-pointer="selectOptions.showPointer"
                 @input="updateSelected" @select="onSelect" @remove="onRemove" @search-change="onSearchChange"
                 @tag="addTag" @open="onOpen" @close="onClose" :option-height="selectOptions.optionHeight">
        <span slot="noResult">{{ selectOptions.noResult }}</span>
        <span slot="noOptions">{{ selectOptions.noOptions }}</span>
        <span slot="maxElements">{{ selectOptions.maxElements }}</span>
        <template slot="afterList" v-if="selectOptions.lazy">
            <li v-observe-visibility="reachedEndOfList" style="height: 2px;"></li>
        </template>
    </multiselect>
</template>
<script>
  import Vue from 'vue';
  import {abstractField} from "vue-form-generator";
  import {ObserveVisibility} from 'vue-observe-visibility';
  import debounce from 'lodash/debounce';

  Vue.directive('observe-visibility', ObserveVisibility);

  export default {
    mixins: [abstractField],
    data() {
      return {
        search_string: "",
        sizeModifier: 1
      }
    },
    computed: {
      selectOptions() {
        return this.schema.selectOptions || {};
      },
      options() {
        let values = this.schema.values;
        if (typeof values == "function") {
          return values.apply(this, [this.model, this.schema]);
        } else {
          return values;
        }
      },
      customLabel() {
        if (
          typeof this.schema.selectOptions !== "undefined" &&
          typeof this.schema.selectOptions.customLabel !== "undefined" &&
          typeof this.schema.selectOptions.customLabel === "function"
        ) {
          return this.schema.selectOptions.customLabel;
        } else {
          // this will let the multiselect library use the default behavior if customLabel is not specified
          return undefined;
        }
      }
    },
    watch: {
      search_string(){
        this.$nextTick(() => {
          this.$refs.multiselect.$refs.list.children[0].scrollTop;
        });
      }
    },
    methods: {
      handleOptionsChange(options) {
        this.old_options = options;
        return options;
      },
      repositionDropDown() {
        const elRect = this.$el.getBoundingClientRect();
        const ref = this.$refs.multiselect;
        ref.$refs.list.style.width = `${this.$el.clientWidth}px`;
        ref.$refs.list.style.position = 'fixed';
        if (ref.preferredOpenDirection === 'above') {
          ref.$refs.list.style.bottom = 'auto';
          ref.$refs.list.style.top = `${elRect.bottom - elRect.height}px`;
          ref.$refs.list.style.transform = 'translateY(-100%)';
        } else {
          ref.$refs.list.style.bottom = 'auto';
          ref.$refs.list.style.top = `${elRect.top + elRect.height}px`;
          ref.$refs.list.style.transform = 'translateY(0)';
        }
      },
      handleScroll(e) {
        console.log(this.scrollY)
      },
      updateSelected(value /* , id*/) {
        this.value = value;
      },
      addTag(newTag, id) {
        let onNewTag = this.selectOptions.onNewTag;
        if (typeof onNewTag == "function") {
          onNewTag(newTag, id, this.options, this.value);
        }
      },
      onSearchChange: debounce(function(searchQuery, id) {
        this.search_string = searchQuery;
        this.sizeModifier=1;
        let onSearch = this.selectOptions.onSearch;
        if (typeof onSearch == "function") {
          onSearch(searchQuery, id, this.options, this.sizeModifier);
        }
      }, 200),
      onSelect(/* selectedOption, id */) {
        // console.log("onSelect", selectedOption, id);
      },
      onRemove(/* removedOption, id */) {
        // console.log("onRemove", removedOption, id);
      },
      onOpen(/* id */) {
        // console.log("onOpen", id);
        this.repositionDropDown();
        window.addEventListener('scroll', this.repositionDropDown);
        this.$store.state.layout.content.addEventListener('scroll', this.repositionDropDown);
        const $collapse = $(this.$refs.multiselect.$vnode.elm).parent().parent();
        if ($collapse.hasClass('card-view')) {
          if (!$collapse.parent().hasClass('show')) {
            $collapse.click();
          }
        }
        if(this.selectOptions.lazy) {
          this.onSearchChange("", null, this.sizeModifier);
        }
      },
      onClose(/* value, id */) {
        window.removeEventListener('scroll', this.repositionDropDown);
        this.$store.state.layout.content.removeEventListener('scroll', this.repositionDropDown);
      },
      reachedEndOfList: debounce(function(reached) {
        if (reached && this.options.length > 0 && this.options.length < this.schema.total()) {
          this.$nextTick(()=>{
            this.sizeModifier++;
            let onSearch = this.selectOptions.onSearch;
            if (typeof onSearch == "function") {
              onSearch(this.search_string, null, this.options, this.sizeModifier);
            }
          });
        }
      }, 200),
    },
    created() {
      // Check if the component is loaded globally
      if (!this.$root.$options.components["multiselect"]) {
        console.error("'vue-multiselect' is missing. Please download from https://github.com/monterail/vue-multiselect and register the component globally!");
      }
    }
  };
</script>