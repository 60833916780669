export const ResponsiveTableMixin = {
  data() {
    return {
      dynamicOptions: {
        cardView: true
      },
      skipCreate: false
    }
  },
  provide: function () {
    return {
      removeCollapse: this.removeCollapse,
      setCollapse: this.setCollapse,
    }
  },
  watch: {
    $mq: {
      handler(newVal) {
        if (['xs', 'sm', 'md'].includes(newVal)) {
          if (this.dynamicOptions['cardView'] === false) {
            this.setDynamicOption('cardView', true);
            this.setDynamicOption('showColumns', false);
            this.removeCollapse();
            this.setCollapse();
            this.changeTableView();
          }
        } else {
          if (this.dynamicOptions['cardView'] === true) {
            this.setDynamicOption('cardView', false);
            this.setDynamicOption('showColumns', true);
            this.changeTableView();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    setDynamicOption(option, value) {
      if (this.dynamicOptions.hasOwnProperty(option)) {
        this.dynamicOptions[option] = value;
      }
    },
    onPostBody(data) {
      if (data && data.length > 0) {
        this.setCollapse();
      }
    },
    onPreBody(data) {
      if (data && data.length > 0) {
        this.removeCollapse();
      }
    },
    removeCollapse(element) {
      if (element) {
        element = $(element).find('.card-views');
      } else {
        element = $('.card-views');
      }
      element.each((key, value) => {
        $(value).children(":first").off('click');
      });
    },
    setCollapse(element, show_default=false) {
      if (element) {
        element = $(element).find('.card-views');
      } else {
        element = $('.card-views');
      }
      element.each((key, value) => {
        let $row = $(value);
        $row.css('height', 'auto');
        const h = $row.height();
        const collapsedH = $row.children(":first").height();
        $row.css('height', collapsedH);
        const $firstColumn = $row.children(":first");
        if(show_default) {
          $row.toggleClass('show');
          $row.css('height', h);
        }
        $firstColumn.on('click', (e) => {
          if (($(e.target).hasClass('multiselect__input') || $(e.target).hasClass('multiselect__select') || $(e.target).hasClass('multiselect__single')) && $row.hasClass('show')) {
            return;
          }
          if ($row.hasClass('show')) {
            $row.css('height', collapsedH);
          } else {
            $row.css('height', h);
          }
          $row.toggleClass('show');
        });
      });
    },
    handleRefreshOptions() {
      //implement in component if you need
    },
    changeTableView() {
      //implement in component if you need
    }
  },
};