import Vue from 'vue';
import get from 'lodash/get';
import {addRoutesFromModule, importConfig} from "@/utils";
import store from "@/store/index";
import loginLogoDefault from '@/assets/logo/logo.png';

export const DEVICE_PREFERENCES_MAP = {
  MOBILE: (media) => media === 'xs',
  TABLET: (media) => ['sm', 'md'].includes(media),
  DESKTOP: (media) => ['lg', 'xl'].includes(media),
};


export default {
  namespaced: true,
  state: {
    user: null,
    tenant: null,
    endpoints: {
      login: window.Urls["login"](),
      logout: window.Urls["restFramework:logout"]()
    },
    current_language: null,
    csrftoken: null,
    flags: {
      'url': '/static/images/',
      'format': 'svg'
    },
    user_field_config: null,
    routes_updated: false
  },

  mutations: {
    setAuthUser(state, user) {
      Vue.set(state, 'user', user);
      Vue.set(state, 'current_language', user.language);
      moment.locale(user.language);
    },
    removeUser(state) {
      state.user = null;
    },
    updatePreference(state, {tableName, category, attr_name, attr_value}) {
      const current_preferences = get(state.user, 'preferences', {});
      const current_table_preferences = get(state.user, `preferences.${tableName}`, {});
      const current_category_preferences = get(state.user, `preferences.${tableName}.${category}`, {});

      Vue.set(state.user, 'preferences', {
        ...current_preferences,
        [tableName]: {
          ...current_table_preferences,
          [category]: {
            ...current_category_preferences,
            [attr_name]: attr_value
          }
        }
      });
    },
    updateViews(state, {tableName, viewFilter, viewName="default"}) {
      const current_views = get(state.user, 'views', {});
      const current_table_views = get(state.user, `views.${tableName}`, {});
      Vue.set(state.user, 'views', {
        ...current_views,
        [tableName]: {
          ...current_table_views,
          [viewName]: viewFilter
        }
      });
    },
    changeLanguage(state, language_code) {
      Vue.set(state, 'current_language', language_code);
    },
    setCsrfToken(state, csrftoken) {
      state.csrftoken = csrftoken;
    },
    setUserFieldConfig(state, user_field_config) {
      state.user_field_config = user_field_config;
    },
    setTenant(state, tenant) {
      state.tenant = tenant;
    },
    setNotifications(state, notifications) {
      state.user.notifications = notifications;
    }
  },
  getters: {
    getFullName: state => state.user_field_config && get(state.user, state.user_field_config.full_name),
    getDealerId: state => state.user_field_config && get(state.user, state.user_field_config.dealer_id),
    getEmail: state => state.user_field_config && get(state.user, state.user_field_config.email),
    getDealerPersonalNumber: state => state.user_field_config && get(state.user, state.user_field_config.personal_number),
    getPreferencesByTable: state => tableName => {
      return get(state.user, `preferences.${tableName}`, {})
    },
    getViewsByTable: state => tableName => {
      return get(state.user, `views.${tableName}.default`, {})
    },
    isFeatureSupported: state => feature_name => {
      if (state.user.config_data && !Object.keys(state.user.config_data.ALL_FEATURES).includes(feature_name)) {
        console.error(`${feature_name} is not supported feature. Choices are ${Object.keys(state.user.config_data.ALL_FEATURES).join(',')}`)
      }
      return get(state.user, 'config_data.SUPPORTED_FEATURES', []).includes(feature_name)
    },
    isDemo: state => state.user?.config_data?.is_demo,
    getUserConstants: state => variable_name => {
      return state.user?.config_data?.[variable_name]
    },
    getTenantConstantRoles: state => {
      return state.tenant.preferences?.user_roles
    },
    hasPerm: state => perms => {
      return perms.every(perm => state.user.permissions.includes(perm));
    },
    hasGroup: state => group => {
      if (Array.isArray(group)) {
        return group.some((item) => {
          return state.user.auth_groups.includes(item)
        })
      }
      return state.user.auth_groups.includes(group)
    },
    getUserRoles: state => {
      let user = state.user
      let user_roles = Object.values(user.tenant.preferences.user_roles)
      return user_roles.filter((role) => {
        return user.child_groups.includes(role) || role === user.fe_auth_group
      })
    },
    resolveConfigPerm: (state, getters) => groups => {
      if (groups) {
        let groups_arr = typeof groups === 'function' ? groups() : groups
        if (groups_arr === '__all__') {
          return true
        } else if (!groups_arr) {
          return false
        }
        return getters['hasGroup'](groups_arr)
      }
    return true
    },
    loginLogo: (state) => {
      return state.tenant?.login_logo || loginLogoDefault
    }
  },
  actions: {
    updatePreference({commit, state}, {tableName, category, attr_name, attr_value}) {
      return new Promise((resolve, reject) => {
        commit('updatePreference', {tableName, category, attr_name, attr_value});
        this.$http.post(window.Urls['userSetPreferences']('me'), state.user.preferences).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      });
    },
    updateViews({commit, state}, {tableName, viewFilter, viewName="default"}) {
      return new Promise((resolve, reject) => {
        commit('updateViews', {tableName, viewFilter, viewName});
        this.$http.post(window.Urls['userSetViews']('me'), state.user.views).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      });
    },
    fetchUser({commit, state}) {
      return new Promise((resolve, reject) => {
        this.$http.get(window.Urls['user-detail']('me')).then((response) => {
          commit("setAuthUser", response.data)
          commit('setTenant', response.data.tenant)
          importConfig(response.data.tenant.mapping, commit, state).then((module) => {
            resolve(response)
          })
          gtag('event', 'page_view', {
            dimension1: response.data?.tenant_id,
            dimension2: response.data?.username,
            dimension3: response.data?.tenant?.name,
          });
        }).catch((error) => {
          gtag('event', 'page_view');
          reject(error)
        })
      });
    },
    fetchTenant({commit, state}, tenant_slug) {
      return new Promise((resolve, reject) => {
        this.$http.get(window.Urls["tenant-detail"](tenant_slug || 'me')).then((response) => {
          commit('setTenant', response.data)
          importConfig(response.data.mapping, commit, state).then((module) => {
            resolve(response)
          })
        }).catch((error) => {
          reject(error)
        })
      });
    },
    logout({commit, state}) {
      return new Promise((resolve, reject) => {
        this.$http.post(state.endpoints.logout).then((response) => {
          const tenant_slug = state.user.tenant.slug;
          commit("removeUser");
          resolve({response, tenant_slug})
        }).catch((error) => {
          reject(error)
        })
      })
    },
    authenticate({commit, state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.set('username', payload.username);
        formData.set('password', payload.password);
        formData.set('tenant_id', payload.tenant_id);
        if (payload.logout_user_session_id) {
          formData.set('logout_user_session_id', payload.logout_user_session_id);
        }
        this.$http.post(state.endpoints.login, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      });
    },
  }
};