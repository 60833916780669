<template>
  <CustomerAddressSelect
      :paymentAddress="value.payment_address"
      :deliveryAddress="value.delivery_address"
      :branchAddress="value.branch_address"
      :selectedCustomer="value.customer"
      :disabled="disabled"
      :branchAddressLabel="value.branchAddressLabel"
      :deliveryAddressLabel="value.deliveryAddressLabel"
      @on-customer-change="onCustomerChange"
  ></CustomerAddressSelect>
</template>

<script>
  import {abstractField} from "vue-form-generator";
  import CustomerAddressSelect from "@/components/Address/CustomerAddressSelect";

  export default {
    mixins: [abstractField],
    components: {
      CustomerAddressSelect
    },
    methods: {
      onCustomerChange(newVal) {
        this.model['obchadresa_id'] = newVal.customer?.id;
        this.model['obchadresanazev'] = newVal.customer?.name;
        this.model['delivery_address'] = newVal.delivery_address;
        this.model['branch_address'] = newVal.branch_address;
        this.model['payment_address'] = newVal.payment_address;
      }
    }
  };
</script>