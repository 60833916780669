import {mapActions, mapMutations, mapState, mapGetters} from "vuex";
import {SET_CUSTOMER} from "@/store/basket/mutation-types";
import debounce from "lodash/debounce";
import Address from "./Address";

export default {
  props: {
    paymentAddress: {
      required: true
    },
    deliveryAddress: {
      required: true
    },
    branchAddress: {
      required: true
    },
    branchAddressLabel: {
      required: false
    },
    deliveryAddressLabel: {
      required: false
    },
    selectedCustomer: {
      required: true
    },
    disabled: {
      default: true
    }
  },
  components: {
    Address
  },
  computed: {
    ...mapState('basket', ['customersOptions', 'addresses', 'customersLoading']),
    ...mapGetters('basket', ['customer', 'payment_address', 'delivery_address', 'payment_address'])
  },
  methods: {
    ...mapActions('basket', {
      fetchAddresses: 'fetchAddresses',
      updateCheckout: 'updateCheckout',
      searchCustomersAction: 'searchCustomers',
    }),
    ...mapMutations('basket', {
      setCustomerMutation: SET_CUSTOMER
    }),
    searchCustomers: debounce(function (querystring) {
      this.searchCustomersAction(querystring);
    }, 200),
    fetchCustomer(customer) {
      this.fetchAddresses({customer_id: customer.id}).then(() => {
            const data = {
              payment_address: this.addresses.payment_address,
              delivery_address: this.addresses.delivery_address,
              branch_address: this.addresses.branch_address,
              customer: customer
            };
            this.$emit('on-customer-change', data);
          });
    },
    setCustomer(newVal) {
      if (newVal?.id !== this.selectedCustomer?.id) {
        if (newVal != null) {
          this.fetchCustomer(newVal)
        } else {
          this.$emit('on-customer-change', SB_UTILS.getEmptyCustomerModel())
        }
      }
    }
  },
  watch: {
    customer(newCustomer, oldCustomer) {
      if (newCustomer && newCustomer !== oldCustomer && !(this.payment_address && this.delivery_address && this.branchAddress)) {
        this.fetchCustomer(newCustomer)
      }
    },
  },
  created() {
    if (this.customersOptions.length === 0) {
      this.searchCustomersAction('');
    }
  }
}