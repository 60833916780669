<template>
    <div class="raw-html">
        <div v-if="schema.html" v-html="formattedValue"></div>
        <pre v-else-if="schema.lang" v-text="formattedValue"></pre>
        <div v-else v-text="formattedValue"></div>
    </div>

</template>

<script>
  import {abstractField} from "vue-form-generator";

  export default {
    mixins: [abstractField],
    computed: {
      formattedValue() {
        if(this.schema.formatter){
          return this.schema.formatter(this.value);
        }
        return this.value;
      }
    }
  };
</script>
<style lang="scss">
    .raw-html {
        border: 1px solid #e7e7e7;
        padding: 5px;
        max-height: 300px;
        overflow: auto;
    }
</style>