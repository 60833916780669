<template>
  <b-row>
    <b-col xl="6" lg="12">
      <div :class=[selectClasses]>
        <div class="form-group valid required field-vueMultiSelect">
          <div class="d-flex align-items-end">
            <div class="flex-fill">
              <label for="customer_select"><span>{{ gettext('Firma') }}</span></label>
              <div class="field-wrap customer-select">
                <multiselect
                    id="customer_select"
                    label="name"
                    track-by="id"
                    open-direction="bottom"
                    v-bind="SB_CONFIG.commonMultiSelectOptions.selectOptions"
                    :placeholder="gettext('Vyhľadať firmu')"
                    :value="selectedCustomer"
                    :options="customersOptions"
                    :multiple="false"
                    :searchable="true"
                    :loading="customersLoading"
                    :internal-search="false"
                    :close-on-select="true"
                    :options-limit="300"
                    :disabled="disabled"
                    @input="setCustomer"
                    @search-change="searchCustomers">
                  <span slot="noResult">{{ SB_CONFIG.commonMultiSelectOptions.selectOptions.noResult }}</span>
                  <span slot="noOptions">{{ SB_CONFIG.commonMultiSelectOptions.selectOptions.noOptions }}</span>
                  <span slot="maxElements">{{ SB_CONFIG.commonMultiSelectOptions.selectOptions.maxElements }}</span>
                  <template slot="clear">
                    <div v-if="selectedCustomer && !disabled" @mousedown.prevent.stop="setCustomer(null)"
                         class="multiselect__clear"></div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div v-if="!disabled" class="ml-3">
              <router-link tag="a" class="btn btn-secondary"
                           :to="{name: 'CreateCustomersPage'}">
                <i class="fas fa-plus"></i>
              </router-link>
            </div>
          </div>
          <span v-if="paymentAddress == null && customer == null" class="d-inline-block invalid-feedback">
            {{ gettext('Prosím vyberte firmu.') }}
          </span>
        </div>
      </div>
    </b-col>
    <b-col xl="12" lg="12" v-if="paymentAddress">
      <div :class=[addressClasses] class="address__list d-flex md:tw-pt-12">
        <Address :title="gettext('Odberateľ')"
                 :selected-address="paymentAddress">
          <template slot="additional_data" slot-scope="props">
            <ul class="address">
              <li v-if="props.selectedAddress.email">
                <span>{{ gettext('Email') }}: </span>{{ props.selectedAddress.email }}</li>
              <li v-if="props.selectedAddress.phone">
                <span>{{ gettext('Telefón') }}: </span>{{ props.selectedAddress.phone }}</li>
              <li v-if="props.selectedAddress.ico"><span>{{ gettext('IČO') }}: </span>{{ props.selectedAddress.ico }}
              </li>
              <li v-if="props.selectedAddress.dic"><span>{{ gettext('DIČ') }}: </span>{{ props.selectedAddress.dic }}
              </li>
              <li v-if="props.selectedAddress.icdph">
                <span>{{ gettext('IČ DPH') }}: </span>{{ props.selectedAddress.icdph }}</li>
            </ul>
          </template>
        </Address>
        <Address :title="deliveryAddressLabel || gettext('Fakturačná adresa')"
                 :selected-address="deliveryAddress || paymentAddress">
        </Address>
        <Address :title="branchAddressLabel || gettext('Koncový príjemca')"
                 :selected-address="branchAddress || paymentAddress">
        </Address>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import CustomerAddressSelectMixin from "@/components/Address/CustomerAddressSelectMixin";

  export default {
    name: "CustomerAddressSelect",
    mixins: [CustomerAddressSelectMixin],
    props: {
      selectClasses: {
        default: ''
      },
      addressClasses: {
        default: ''
      }
    }
  }
</script>

<style src="./Address.scss" lang="scss"/>