<template>
  <router-view/>
</template>

<script>
  import get from 'lodash/get';
  import qs from "qs";
  import {mapState} from "vuex";
  import { useFavicon } from '@vueuse/core'

  export default {
    name: 'App',
    methods: {
      initNotifications() {
        Messenger.options = {
          extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
          theme: 'air',
        };
      },
      setUpAxios() {
        this.$http.defaults.headers.common['X-CSRFToken'] = this.$cookie.get('csrftoken');
        this.$http.defaults.paramsSerializer = function (params) {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        };
        this.$store.commit('auth/setCsrfToken', this.$cookie.get('csrftoken'));
        this.$http.interceptors.request.use((config) => {
          config.headers['X-CSRFToken'] = this.$cookie.get('csrftoken');
          return config;
        }, (error) => {
          return Promise.reject(error);
        });
        this.$http.interceptors.response.use((response) => {
          return response;
        }, (error) => {
          const status_code = get(error, 'response.status', null);
          if (status_code === 401 || status_code === 403) {
            if (!['LoginDeprecated', 'Login'].includes(this.$route.name)) {
              this.$store.dispatch("auth/logout").then(({tenant_slug}) => {
                if (tenant_slug) {
                  this.$router.push({
                    name: 'Login',
                    params: {
                      expired_or_removed_session: true
                    }
                  });
                } else {
                  this.$router.push({name: 'Login'});
                }
              });
              return Promise.reject(error);
            }
          }
          if (parseInt(status_code) > 499) {
            let errorMsg;
            if (Array.isArray(error.response.data)) {
              errorMsg = Object.values(error.response.data[0])[0];
            } else {
              errorMsg = error.response.data.hasOwnProperty('message') ? error.response.data.message : null;
            }
            Messenger().post({
              message: errorMsg ? errorMsg : gettext(`Nastala chyba pri spracovaní požiadavky. (${status_code})`),
              type: 'error',
              showCloseButton: true
            });
          }
          return Promise.reject(error);
        });
      },
    },
    computed: {
      ...mapState('auth', [
        'current_language',
        'tenant'
      ]),
    },
    watch: {
      current_language: {
        handler(newVal) {
          this.$cookie.set('current_language', newVal)
        }
      },
      tenant: {
        handler(newVal) {
          document.title = newVal.preferences?.page_title || 'iObchodník'
          useFavicon(newVal.favicon)
        }
      }
    },
    created() {
      this.initNotifications();
      this.setUpAxios();
    },
  };
</script>

<style src="./styles/theme.scss" lang="scss"/>