<template>
    <input
            class="form-control"
            :value="value"
            @input="onInput"
            autocomplete="off"
            :disabled="disabled"
            :placeholder="schema.placeholder"
            :readonly="schema.readonly"
            :name="schema.inputName"
            :id="getFieldID(schema)"
    />
</template>

<script>
  import {abstractField, dateFieldHelper} from "vue-form-generator";
  import moment from "moment";
  import debounce from "lodash/debounce";

  let inputFormatDate = "DD.MM.YYYY";
  export let inputFormatDateTime = "DD.MM.YYYY HH:mm";

  export default {
    mixins: [abstractField],
    data() {
      return {
        initialValue: this.value
      }
    },
    computed: {
      minDate(){
        if(!this.schema.minDateModel){
          return "";
        }
        const minDate = this.model[this.schema.minDateModel];
        return minDate ? moment(minDate) : "";
      },
    },
    watch: {
      minDate(newVal) {
        if (newVal) {
          $(this.$el).data('daterangepicker').minDate = newVal;
          if (this.value) {
            if (newVal.diff(moment(this.value, this.getDateFormat())) > 0) {
              this.value = this.formatValueToModel(newVal);
              $(this.$el).data('daterangepicker').setStartDate(newVal);
              $(this.$el).data('daterangepicker').setEndDate(newVal);
            }
          }
        }
      }
    },
    methods: {
      onInput: debounce(function($event) {
        if(!$event.target.value) {
          this.value = '';
          return;
        }
        this.value = moment($event.target.value, this.getDateFormat());
      }, 500),
      getDateFormat() {
        if (this.schema.dateTimePickerOptions && this.schema.dateTimePickerOptions.format) {
          return this.schema.dateTimePickerOptions.format
        } else {
          return this.schema.timePicker ? inputFormatDateTime : inputFormatDate;
        }
      },
      formatValueToField(value) {
        if (value != null) {
          if (typeof value == 'string') {
            return moment(value).format(this.getDateFormat());
          } else {
            // moment
            return value.toString();
          }
        }

        return value;
      },

      formatValueToModel(value) {
        if (value != null) {
          if (typeof value == 'string') {
            return moment(value).toISOString();
          } else {
            // moment
            return value.toISOString();
          }
        }

        return value;
      }
    },
    mounted() {
      this.$nextTick(function () {
        if (window.$ && window.$.fn.daterangepicker && !this.schema.readonly) {
          let input = this.$el.querySelector(".form-control");
          let datePickerConfig = {
            singleDatePicker: true,
            showDropdowns: true,
            timePicker: this.schema.timePicker,
            timePicker24Hour: this.schema.timePicker,
            autoUpdateInput: false,
            cancelClass: "btn-secondary",
            parentEl: $(this.$el).parent(),
            locale: {
              format: this.getDateFormat(),
              applyLabel: this.gettext('Použiť'),
              cancelLabel: this.gettext('Zrušiť')
            }
          };
          if(this.minDate){
            datePickerConfig = {
              ...datePickerConfig,
              minDate: this.minDate
            }
          }
          $(this.$el).daterangepicker(datePickerConfig);
          $(this.$el).on('apply.daterangepicker', (ev, picker) => {
            this.value = picker.startDate;
          });
          $(this.$el).on('hide.daterangepicker', (ev, picker) => {
            if(this.value){
              this.value = picker.startDate;
            }
          });
          $(this.$el).on('show.daterangepicker', (ev, picker) => {
            if(this.value && this.value !== picker.startDate._i){
              $(this.$el).data('daterangepicker').setStartDate(this.value);
              $(this.$el).data('daterangepicker').setEndDate(this.value);
            }
          });
        } else {
          console.warn(
            "Daterangepicker library is missing. Please download from http://www.daterangepicker.com/#examples and load the script and CSS in the HTML head section!"
          );
        }
      });
      let unwatch = this.$watch('value', (newVal) => {
        if (newVal) {
          $(this.$el).data('daterangepicker').setStartDate(newVal);
          $(this.$el).data('daterangepicker').setEndDate(newVal);
        }
        unwatch();
      });
    }
  };
</script>
<style lang="scss">
</style>