<template>
  <div class="bootstrap-table-wrapper">
    <div class="bootstrap-table">
      <div class="fixed-table-container">
        <div class="fixed-table-body">
          <table class="table table-bordered">
            <thead v-show="!cardView">
            <tr>
              <template v-for="field in schema.subFields">
                <th v-if="fieldVisible(field) || showRemove(field)"
                    :style="field.colStyle" :class="{'d-none': field.inputType === 'hidden'}">
                  <div class="th-inner" v-if="field.label"><span :title="field.titleTooltip"
                                                                 v-b-tooltip.hover>{{ field.label }}</span>
                  </div>
                </th>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(single_model, index) in getPaginated" :key="index">
              <template v-if="cardView">
                <td :colspan="visibleColumnsCount" :class="{readonly: formOptions.readonly}">
                  <div ref="cardViews" class="card-views">
                    <div v-for="(field, inner_index) in schema.subFields"
                         :key="`${index}-${inner_index}`"
                         v-if="(field.type !== 'remove' || (field.type === 'remove' && edit)) && field.inputType !== 'hidden'"
                         class="card-view">
                      <span class="card-view-title" style="">{{ field.label }}</span>
                      <span class="card-view-value">
                                                <template v-if="field.type === 'remove'">
                                                    <button v-if="edit" @click="removeItem(single_model)"
                                                            class="btn btn-outline-danger remove-button">
                                                        <i class="fas fa-times-circle"
                                                           :title="gettext('Zmazať riadok')"></i>
                                                    </button>
                                                </template>

                                                <component v-else-if="fieldVisible(field, single_model)" ref="child"
                                                           :is="'field-' + field.type"
                                                           :disabled="fieldDisabled(field, single_model)"
                                                           :model="single_model"
                                                           :formOptions="{...formOptions, fieldIdPrefix: `${index}-${inner_index}-`}"
                                                           :schema="field">
                                                </component>
                                            </span>
                    </div>
                  </div>
                </td>
              </template>
              <template v-else>
                <td v-for="(field, inner_index) in schema.subFields"
                    :key="`${index}-${inner_index}`"
                    v-if="fieldVisible(field) || showRemove(field)"
                    :style="field.colStyle" :class="{'d-none': field.inputType === 'hidden'}">
                  <template v-if="field.type === 'remove'">
                    <button v-if="edit" @click="removeItem(single_model)" class="btn remove-button">
                      <i class="fas fa-times-circle" :title="gettext('Zmazať riadok')"></i>
                    </button>
                  </template>

                  <component v-else ref="child" :is="'field-' + field.type"
                             :disabled="fieldDisabled(field, single_model)"
                             :model="single_model"
                             :formOptions="{...formOptions, fieldIdPrefix: `${index}-${inner_index}-`}"
                             :schema="field"></component>
                </td>
              </template>
            </tr>
            <tr v-if="getPaginated.length === 0">
              <td :colspan="visibleColumnsCount" class="table-empty">{{ gettext("Nenašli sa žiadne položky")}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <div v-if="edit" class="add-more-wrapper">
          <button class="btn btn-secondary"
                  @click="$store.commit('form/addItem', vfg.schema)"><i
              class="fas fa-plus mr-2"></i>{{ gettext('Pridať') }}
          </button>
        </div>
        <b-pagination v-if="!edit && model.data"
                      v-model="formOptions.currentPage"
                      :perPage="formOptions.perPage"
                      :total-rows="model.data.length"
                      :hide-goto-end-buttons="true"
                      align="right">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {abstractField} from "vue-form-generator";
  import {component} from "vue-form-generator/dist/vfg-core";
  import {ResponsiveTableMixin} from "../../mixins/ResponsiveTableMixin";
  import isScreen from "../../core/screenHelper";
  import Vue from "vue";
  import {isFunction, isNil} from "lodash";

  export default {
    mixins: [abstractField, ...component.components.formGroup.mixins, ResponsiveTableMixin],
    components: component.components.formGroup.components,
    data() {
      return {
        extraAdded: false
      }
    },
    watch: {
      cardView: {
        handler(newVal) {
          Vue.nextTick(() => {
            this.setUpCollapse();
          });
        },
        immediate: true
      },
      tabIdActivated: {
        handler(newVal) {
          Vue.nextTick(() => {
            this.setUpCollapse();
          });
        },
      },
    },
    computed: {
      edit() {
        return this.$store.state.form.isEdit;
      },
      tabIdActivated() {
        return this.$store.state.layout.tabIdActivated;
      },
      visibleColumnsCount() {
        return this.schema.subFields.filter(field => {
          return field.inputType !== 'hidden' && (field.type !== 'remove' || (field.type === 'remove' && this.edit))
        }).length;
      },
      getPaginated() {
        let active_items = this.model.data ? this.model.data.filter(item => !item.hasOwnProperty('deleted') || item.deleted === false) : [];
        if (!this.edit && this.formOptions.currentPage && this.model.data) {
          const from = (this.formOptions.currentPage - 1) * this.formOptions.perPage;
          const to = from + this.formOptions.perPage;
          return active_items.slice(from, to);
        }
        return active_items;
      },
      cardView() {
        return ['xs', 'sm', 'md'].includes(this.$mq)
      }
    },
    methods: {
      showRemove(field) {
        return field.type === 'remove' && this.edit;
      },
      setUpCollapse() {
        if (this.$refs.cardViews) {
          this.removeCollapse(this.$refs.cardViews);
          if (this.cardView) {
            this.setCollapse(this.$refs.cardViews);
          }
        }
      },
      removeItem(model) {
        const index = this.model.data.findIndex(item => item.id === model.id);
        Vue.set(this.model.data[index], 'deleted', true);
        this.$store.commit('form/removeItem', model);
      },
      fieldDisabled(field, model) {
        if (isFunction(field.disabled)) return field.disabled.call(this, model, field, this);

        if (isNil(field.disabled)) return false;

        return field.disabled;
      },
      fieldVisible(field, model) {
        if(field.type === 'remove') {
          return false;
        }
        if (isFunction(field.visible)) {
          return field.visible.call(this, this.model, field, this);
        }
        if (isNil(field.visible)) {
          return true;
        }
        return field.visible;
      },
    }
  };
</script>

<style src="./../ServerTable/ServerTable.scss" lang="scss" scoped/>

<style scoped>
  .bootstrap-table-wrapper {
    padding: 0;
  }

  .add-more-wrapper {
    margin: 10px 0;
  }
</style>

