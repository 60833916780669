export let VueFormGeneratorResources = {
  fieldIsRequired: gettext("Toto pole je povinné!"),
  invalidFormat: gettext("Nesprávny formát!"),

  numberTooSmall: gettext("Číslo je príliš malé! Minimum: {0}"),
  numberTooBig: gettext("Číslo je príliš veľké! Maximum: {0}"),
  invalidNumber: gettext("Číslo je nevalidné."),
  invalidInteger: gettext("Hodnota nie je číslo."),

  textTooSmall: gettext("Dĺžka textu je príliš malá! Hodnota: {0}, Minimum: {1}"),
  textTooBig: gettext("Dĺžka textu je príliš veľká! Hodnota: {0}, Maximum: {1}"),
  thisNotText: gettext("Hodnota nie je typu text!"),

  thisNotArray: gettext("Hodnota nie je typu poľe!"),

  selectMinItems: gettext("Vyberte minimálne {0} položiek!"),
  selectMaxItems: gettext("Vyberte maximálne {0} položiek!"),

  invalidDate: gettext("Nevalidný dátum!"),
  dateIsEarly: gettext("Zadaný dátum musí byť po {1}"),
  dateIsLate: gettext("Zadaný dátum musí byť pred {1}"),

  invalidEmail: gettext("Nevalidná emailová adresa!"),
  invalidURL: gettext("Nevalidná URL!")
};