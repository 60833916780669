//basket
export const SET_WHOLE_BASKET = 'SET_WHOLE_BASKET';
export const SET_LINES = 'SET_LINES';
export const SET_CHECKOUT_STEP_NUMBER = 'SET_CHECKOUT_STEP_NUMBER';
export const SET_CHECKOUT_PAYMENT_ADDRESS = 'SET_CHECKOUT_PAYMENT_ADDRESS';
export const SET_CHECKOUT_DELIVERY_ADDRESS = 'SET_CHECKOUT_DELIVERY_ADDRESS';
export const SET_CHECKOUT_BRANCH_ADDRESS = 'SET_CHECKOUT_BRANCH_ADDRESS';
export const SET_CHECKOUT_PAYMENT_SERVICE = 'SET_CHECKOUT_PAYMENT_SERVICE';
export const SET_CHECKOUT_DELIVERY_SERVICE = 'SET_CHECKOUT_DELIVERY_SERVICE';
export const SET_CHECKOUT_WAREHOUSE = 'SET_CHECKOUT_WAREHOUSE';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_SHOW_BASKET_MODAL = 'SET_SHOW_BASKET_MODAL';
export const SET_LOADING = 'SET_LOADING';
export const SET_BASKET_UPDATING = 'SET_BASKET_UPDATING';
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_CUSTOMERS_OPTIONS = 'SET_CUSTOMERS_OPTIONS';
export const SET_CUSTOMERS_LOADING = 'SET_CUSTOMERS_LOADING';
export const SET_PRICE_FORMATTER = 'SET_PRICE_FORMATTER';
export const ADD_BASKET_ITEM = 'ADD_BASKET_ITEM';
export const SET_WIZARD_REF = 'SET_WIZARD_REF';
export const UPDATE_CHECKOUT_DATA = 'UPDATE_CHECKOUT_DATA';
export const SET_FORM_REF = 'SET_FORM_REF';
export const SET_DISCOUNTS = 'SET_DISCOUNTS';
export const SET_VISIBLE_FIELDS = 'SET_VISIBLE_FIELDS';
export const SET_IS_ASYNC = 'SET_IS_ASYNC';
export const UPDATE_ASYNC_BATCH = 'UPDATE_ASYNC_BATCH';
export const UPDATE_ASYNC_LINE = 'UPDATE_ASYNC_LINE';
export const SET_PRODUCT_MULTIPLIER = 'SET_PRODUCT_MULTIPLIER';
export const SET_PRODUCT_MULTIPLIER_UNIT = 'SET_PRODUCT_MULTIPLIER_UNIT';
export const SET_DELETED_PRODUCT_MESSAGES = 'SET_DELETED_PRODUCT_MESSAGES';
