import Vue from "vue";
import {formatDate, formatDateTime, formatMoment, formatPercentage, formatPrice, formatPriceWithCurrency} from "./utils";
import get from "lodash/get";

Vue.filter('formatPrice', function (value) {
  return formatPrice(value)
});

Vue.filter('formatPercentage', function (value) {
  return formatPercentage(value)
});

Vue.filter('formatPriceWithCurrency', function (value, currencyCode) {
  return formatPriceWithCurrency(value, currencyCode)
});


Vue.filter('formatMoment', function (value, format) {
  return formatMoment(value)(format)
});

Vue.filter('formatDate', function (value) {
  return formatDate(value)
});

Vue.filter('formatDateTime', function (value) {
  return formatDateTime(value)
});

Vue.filter('get', function (item, key, default_value) {
  return get(item, key, default_value)
});