<template>
  <div>
    <b-form-checkbox
            :id="getFieldID(schema)"
            v-model="value"
            :autocomplete="schema.autocomplete"
            :disabled="disabled"
            :name="schema.inputName"
            :class="schema.fieldClasses"
            :required="schema.required"
    >
        {{ schema.label }}
    </b-form-checkbox>
    <p v-if="schema.note" class="tw-mt-4 tw-text-gray-400 tw-text-sm">{{ schema.note }}</p>
  </div>
</template>

<script>
  import {abstractField} from "vue-form-generator";

  export default {
    mixins: [abstractField]
  };
</script>

<style lang="scss">
    .vue-form-generator .field-checkbox input {
        margin-left: 12px;
    }
    .field-checkboxB4 > label{
        display: none;
    }
</style>